<template>
  <el-dialog
      title=""
      v-model="open"
      :close-on-click-modal="0"
      :close-on-press-escape="0"
      :show-close="0"
      width="700px"
      top="25vh"
      custom-class="relative">

    <div class="p-8">
      <div class="flex justify-center pb-8">
        <div class="bg-bm-primary width-5rem p-6 rounded-full">
          <img :src="require('@/assets/icons/cross_white.svg')" alt="FEHLER" class="h-8">
        </div>
      </div>
      <div class="text-center px-12">
        <h1 class="modal-headline">Transaktion abgebrochen!</h1><br>
        <p class="text-xl font-thin">
          Der Vorgang wurde abgebrochen!
        </p><br><br>
        <fmu-button button-type="outline" @button-click="toHome()">zurück zur Startseite</fmu-button>
      </div>
      <br>
    </div>
  </el-dialog>
</template>

<script>
import {useRoute} from "vue-router";
import {onMounted, ref} from "vue"
import {ElDialog} from "element-plus"
import FmuButton from "@/components/theme/button/FmuButton";
import {useSubStore} from "@/store/subs/sub-store";

export default {
  name: 'CancelledModal',
  components: {FmuButton, ElDialog},
  setup() {
    const route = useRoute()
    const open = ref(true)
    const subStore = useSubStore()

    onMounted(() => {
      subStore.finalPaymentProcess('CANCELED', route.params.payment_token)
    })

    const toHome = () => {
      window.location.replace("/")
    }

    return {ElDialog, open, toHome}
  }
}
</script>

<style scoped>
</style>
